// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Peralta&family=Righteous&family=Russo+One&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  margin: 0;\n  padding: 0;\n}\n\nhtml {\n  box-sizing: border-box;\n  font-size: 62.5%;\n  scroll-behavior: smooth;\n  overflow-x: hidden;\n}\n\nbody {\n  transition: all 0.2s;\n}\n\na,\na:visited {\n  text-decoration: none;\n}\n\np {\n  text-align: justify;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  font-family: \"Russo One\", cursive;\n  color: #fff;\n  transition: all 0.2s;\n}\n\np,\nspan,\na,\nli {\n  color: #fff;\n  font-family: \"Righteous\", cursive;\n  transition: all 0.2s;\n}\n\nsvg,\ni {\n  transition: all 0.2s;\n}", "",{"version":3,"sources":["webpack://./src/App.scss","webpack://./src/utils/styles/variables.scss"],"names":[],"mappings":"AAEA;EACC,SAAA;EACA,UAAA;AAAD;;AAGA;EACC,sBAAA;EACA,gBAAA;EACA,uBAAA;EACA,kBAAA;AAAD;;AAEA;EACC,oBAAA;AACD;;AAEA;;EAEC,qBAAA;AACD;;AACA;EACC,mBAAA;AAED;;AACA;;;;;;EAMC,iCAAA;EACA,WC/BO;EDgCP,oBAAA;AAED;;AACA;;;;EAIC,WCvCO;EDwCP,iCAAA;EACA,oBAAA;AAED;;AACA;;EAEC,oBAAA;AAED","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Peralta&family=Righteous&family=Russo+One&display=swap\");\n@import \"./utils/styles/variables.scss\";\n* {\n\tmargin: 0;\n\tpadding: 0;\n}\n\nhtml {\n\tbox-sizing: border-box;\n\tfont-size: 62.5%; // setting rem\n\tscroll-behavior: smooth;\n\toverflow-x: hidden;\n}\nbody {\n\ttransition: all 0.2s;\n}\n\na,\na:visited {\n\ttext-decoration: none;\n}\np {\n\ttext-align: justify;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n\tfont-family: \"Russo One\", cursive;\n\tcolor: $light;\n\ttransition: all 0.2s;\n}\n\np,\nspan,\na,\nli {\n\tcolor: $light;\n\tfont-family: \"Righteous\", cursive;\n\ttransition: all 0.2s;\n}\n\nsvg,\ni {\n\ttransition: all 0.2s;\n}\n","$dark: #070707;\n$light: #fff;\n$primary-color: #77ff00;\n$light-fill: red;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
